/* @import "~antd/dist/antd.css";

.site-layout-background {
    background-color: #fff;
}


@media only screen and (min-width: 991px) {
.show-mobile {
    display: none;
}
.hide-desktop {
    display: none;
}
}

@media only screen and (max-width: 991px) {
    .hide-mobile {
        display: none;
    }
    }


   */
   

.ant-layout-sider-trigger {
  display: none;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSJpY29uIGljb24tdGFibGVyIGljb24tdGFibGVyLWNoZXZyb24tZG93biIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0cm9rZS13aWR0aD0iMSIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIGZpbGw9Im5vbmUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PHBhdGggc3Ryb2tlPSJub25lIiBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIj48L3BhdGg+PHBhdGggZD0iTTYgOWw2IDZsNiAtNiI+PC9wYXRoPjwvc3ZnPg==");
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-chevron-up' width='24' height='24' viewBox='0 0 24 24' stroke-width='1' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M6 15l6 -6l6 6' /%3E%3C/svg%3E");
}

.accordion-button:not(.collapsed) {
    border: 1px;
    border-radius: 5px !important;
    color: #fff;
    background-color: #61d9ca;
    font-size: 18px;
    font-weight: 800;
}

.button.accordion-button.collapsed{
  font-size: 17px;
  font-weight: 700;
}


.accordion-style .accordion-button{
  font-size: 16px;
    font-weight: 700;
}


.center-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.center-div a {
  font-size: 17px;
  font-weight: 600;
  text-decoration: none;
  /* margin: 0 10px; */
  color: gray;
}

.divider {
  height: 20px;
  width: 1px;
  background-color: black; /* Divider color */
  margin: 0 10px;
}

@media screen and (max-width: 600px) and (min-width: 400px) {
  .center-div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
  }
  
  .center-div a {
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    /* margin: 0 10px; */
  }
}

@media screen and (max-width: 399px) and (min-width: 260px) {
  .center-div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
  }
  .center-div a {
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;
    /* margin: 0 10px; */
  }
}

.content-card .fw-bold{
  font-size: 17px;
}