.user-profile-header-banner img {
  width: 100%;
  object-fit: cover;
  height: 250px;
}

.user-profile-header {
  margin-top: -2rem;
}

.user-profile-header .user-profile-img {
  border: 5px solid #fff;
  width: 120px;
}

.dataTables_wrapper .card-header .dataTables_filter label {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.overflow-table-controller {
  overflow: hidden;
}

@media (max-width: 1405px) {
  .overflow-table-controller {
    overflow: auto;
  }
}
@media (max-width: 767.98px) {
  .user-profile-header-banner img {
    height: 150px;
  }

  .user-profile-header .user-profile-img {
    width: 100px;
  }
}

.cursor_pointer_custom {
  cursor: pointer;
}
