.slider-container {
  position: relative;
  width: 100%;
}
.slider {
  width: 100%;
  margin: 10px 0;
  appearance: none;
  height: 3px;
  border-radius: 5px;
  background: #61d9ca;
  outline: none;
  transition: background 0.15s ease-in-out;
}
.slider::-webkit-slider-thumb {
  appearance: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 5px solid #f5fffc;
  background: #61d9ca;
  cursor: pointer;
  transition: background 0.15s ease-in-out;
}
.slider::-moz-range-thumb {
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #007bff;
  cursor: pointer;
}
.ruler {
  position: relative;
  width: 100%;
  height: 20px;
}
.ruler-mark {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  height: 7px;
  background-color: #383838;
}
.ruler-label {
  position: absolute;
  /* bottom: 10px; */
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 15px;
  color: #464646;
}
