$color_1: #5d596c;
$background-color_1: #fff;
$background-color_2: #f8f7fa;
$background-color_3: #2f3349;
$background-color_4: #25293c;
$border-color_1: #ea5455;

.authentication-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  width: 100%;
  .authentication-inner {
    width: 100%;
  }
  .auth-input-wrapper {
    .auth-input {
      max-width: 50px;
      padding-left: 0.4rem;
      padding-right: 0.4rem;
      font-size: 150%;
    }
  }

  .colr-forgot {
    color: inherit;
  }
}

.authentication-wrapper.authentication-basic {
  align-items: center;
  justify-content: center;
  .card-body {
    padding: 2rem;
  }
  .authentication-inner {
    max-width: 400px;
    position: relative;
    &:before {
      width: 238px;
      height: 233px;
      content: " ";
      position: absolute;
      top: -55px;
      left: -40px;
      background-image: url("data:image/svg+xml,%3Csvg width='239' height='234' viewBox='0 0 239 234' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='88.5605' y='0.700195' width='149' height='149' rx='19.5' stroke='%2361D9CA' stroke-opacity='0.16'/%3E%3Crect x='0.621094' y='33.761' width='200' height='200' rx='10' fill='%2361D9CA' fill-opacity='0.08'/%3E%3C/svg%3E%0A");
    }
    &:after {
      width: 180px;
      height: 180px;
      content: " ";
      position: absolute;
      z-index: -1;
      bottom: -30px;
      right: -56px;
      background-image: url("data:image/svg+xml,%3Csvg width='181' height='181' viewBox='0 0 181 181' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1.30469' y='1.44312' width='178' height='178' rx='19' stroke='%2361D9CA' stroke-opacity='0.16' stroke-width='2' stroke-dasharray='8 8'/%3E%3Crect x='22.8047' y='22.9431' width='135' height='135' rx='10' fill='%2361D9CA' fill-opacity='0.08'/%3E%3C/svg%3E");
    }
  }
}
.authentication-wrapper.authentication-cover {
  align-items: flex-start;
  .authentication-inner {
    height: 100%;
    margin: auto 0;
    .auth-cover-bg {
      width: 100%;
      margin: 2rem 0 2rem 2rem;
      height: calc(100vh - 4rem);
      border-radius: 1.125rem;
      position: relative;
      .auth-illustration {
        max-height: 65%;
        z-index: 1;
      }
    }
    .platform-bg {
      position: absolute;
      width: 100%;
      bottom: 0%;
      left: 0%;
      height: 35%;
    }
    .auth-multisteps-bg-height {
      height: 100vh;
      > img {
        &:first-child {
          z-index: 1;
        }
      }
    }
  }
}
#twoStepsForm {
  .fv-plugins-bootstrap5-row-invalid {
    .form-control {
      border-color: $border-color_1;
    }
  }
}
.light-style {
  .authentication-wrapper.authentication-bg {
    background-color: #ffffff;
  }
  .auth-cover-bg-color {
    background-color: $background-color_2;
  }
}

h6 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1.37;
  color: $color_1;
}
.h6 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1.37;
  color: $color_1;
}
h5 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1.37;
  color: $color_1;
}
.h5 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1.37;
  color: $color_1;
}
h4 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1.37;
  color: $color_1;
}
.h4 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1.37;
  color: $color_1;
}
h3 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1.37;
  color: $color_1;
}
.h3 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1.37;
  color: $color_1;
}
h2 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1.37;
  color: $color_1;
}
.h2 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1.37;
  color: $color_1;
}
h1 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1.37;
  color: $color_1;
}
.h1 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1.37;
  color: $color_1;
}
@media (max-width: 575.98px) {
  .authentication-wrapper.authentication-basic {
    .authentication-inner {
      &:before {
        display: none;
      }
      &:after {
        display: none;
      }
    }
  }
  .authentication-wrapper {
    .auth-input-wrapper {
      .auth-input {
        font-size: 1.125rem;
      }
    }
  }
}
@media (max-height: 636px) {
  .auth-multisteps-bg-height {
    height: 100% !important;
  }
}

.w-px-400 {
  .mb-4 {
    margin-bottom: 16px !important;
  }
  .app-brand {
    .app-brand-logo {
      img {
        width: 240px;
      }
    }
  }
}

.colr-forgot {
  small {
    font-size: 80%;
  }
  color: inherit;
  cursor: pointer;
}

.light-style.authentication-wrapper.authentication-bg {
  background-color: #ffffff;
}

input {
  box-shadow: 0 0.125rem 0.25rem #a5a3ae4d !important;
}

.logo-style {
  width: 160px;
}

.created:hover{
  color: #25293c;
}

.jumbotron {
//   padding: 100px 16px 100px 16px;
  background: radial-gradient(
      87.42% 212.12% at 2.35% 0.94%,
      #61d9ca 0%,
      #3ca497 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  

	.lead {
		color: white;
		font-size: 26px;
		font-weight: 800;
		line-height: 36px;
		letter-spacing: 0px;
		text-align: left;	

	  
  }
}

.setup {
  padding: 13px 26px;
  box-shadow: 0px 2px 4px 0px #a5a3ae4d;
  border-radius: 8px;
  border: #ffffff;
  background: #ffffff;

  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.4300000071525574px;
  text-align: left;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSJpY29uIGljb24tdGFibGVyIGljb24tdGFibGVyLWNoZXZyb24tZG93biIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0cm9rZS13aWR0aD0iMSIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIGZpbGw9Im5vbmUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PHBhdGggc3Ryb2tlPSJub25lIiBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIj48L3BhdGg+PHBhdGggZD0iTTYgOWw2IDZsNiAtNiI+PC9wYXRoPjwvc3ZnPg==");
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-chevron-up' width='24' height='24' viewBox='0 0 24 24' stroke-width='1' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M6 15l6 -6l6 6' /%3E%3C/svg%3E");
}

.accordion-button:not(.collapsed) {
    border: 1px;
    border-radius: 5px !important;
    color: #fff;
    background-color: #61d9ca;
    font-size: 18px;
    font-weight: 800;
}

.box-sh-hide{
  box-shadow: 0 0.125rem 0.25rem #ffffff !important;

}

input.flatpickr-input {
  border-radius: 10px;
  box-sizing: border-box;
    margin: 0;
    padding: 4px 11px 4px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1;
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    position: relative;
    display: inline-flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    transition: border 0.2s,box-shadow 0.2s;
    width: 100%;
}