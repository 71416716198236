/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.card-info small {
  font-size: 14px;
  font-weight: normal;
}
.rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child {
  width: 334px;
  min-width: 0 !important;
  max-width: 334px;
}

.secondaryCheckbox input[type="checkbox"]:checked {
  accent-color: #61d9ca;
  background-color: white;
}

.avatorImageCustom {
  width: 36px !important;
  height: 36px !important;
  border-radius: 50%;
}

.flagImageCustom {
  width: 16px !important;
  height: 16px !important;
  /* border-radius: 50%; */
}
.roleIconCustom {
  border-radius: 50%;
  padding: 5px;
  margin-right: 12px;
  width: 30px !important;
  height: 30px !important;
}

.bg-label-primary_custom {
  background-color: #eae8fd !important;
  color: #7367f0 !important;
}

.flatpickr-input::selection {
  display: none;
}
.flatpickr-input:focus {
  border: 2px solid #61d9ca;
  outline: none;
}

.focuesBorderPrimary {
  /* display: none !important; */
  border-color: red !important;
  box-shadow: none !important;
  outline: none !important;
}

.css-t3ipsp-control {
  border-color: #61d9ca !important ;
  box-shadow: 0 0 1px #61d9ca !important ;
}

/* =================== form modal custom styling ===================  */

.custom-modal-form-style label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.custom-modal-form-style input[type="text"],
.custom-modal-form-style input[type="number"],
.custom-modal-form-style textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 15px;
}

.custom-modal-form-style textarea {
  min-height: 100px;
  resize: vertical; /* allows vertical resizing */
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 0px;
}
.responsive-width-control {
  max-width: 100vw !important;
}

/* overwrited css  */
.bg-menu-theme.menu-vertical .menu-item.active > .menu-link:not(.menu-toggle) {
  /* background: linear-gradient(72.47deg, #7367f0 22.16%, rgba(115, 103, 240, 0.7) 76.47%); */
  background: rgb(97, 217, 202) !important;
  box-shadow: 0px 2px 6px 0px rgba(97, 217, 202, 0.48) !important;
}

.card {
  background-clip: padding-box;
  /* box-shadow: 0 0.125rem 0.25rem rgba(97, 217, 202, 0.3);  edited */
  box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1) !important;
}

.navbar-detached {
  /* box-shadow: 0 0.125rem 0.25rem rgba(97, 217, 202, 0.3); edited */
  box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1) !important;
}
/* --------------------------------- */
