.right_side{
    padding: 20px;
    border-left: 1px solid #DBDADE;
    border-radius: 0.375rem;
}

.bg_grey{
    background-color: #4b465c14;
    color: black;
}
.color-input{
    width: 300px;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #DBDADE;
    border-radius: 0.6rem;
}

.default-prompt-container{
    background-color: #7367f014;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.border-20{
    border-radius: 20px;
}

.border-top-20{
    border-radius: 20px 20px 0 0;
}

.textarea_chatbot{
    border: 0;
    resize: none;
}

.textarea_chatbot:focus-visible, .textarea_chatbot:focus{
    outline: none;
}

.dropzone .dz-message .dz-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.dropzone .dz-message {
    text-align: center;
    margin: 3em 0;
}

.dropzone.dz-clickable {
    cursor: pointer;
}


.dropzone {
    min-height: 150px;
    border: 1px solid rgba(0,0,0,.8);
    border-radius: 5px;
    padding: 20px;
}
.dropzone {
    border: 1px dashed #dcdbdf;
}

@media screen and (max-width: 1200px){
    .left_side{
        border-bottom: 1px solid #DBDADE;
    }
    .right_side{
        border-left: 0
    }
}
